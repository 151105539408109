import { InternalLink, Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import { Card, CardContent, Rating, Stack } from "@mui/material";
import { RootPaths } from "@src/appV2/App/paths";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { formatDistanceInMiles } from "@src/appV2/lib/utils/distance";
import { useGetRatingAggregates } from "@src/appV2/Reviews/api/useGetRatingAggregates";
import { RATING_AGGREGATE_EMPTY } from "@src/appV2/Reviews/constants";
import { useRouteMatch } from "react-router-dom";

import { type FacilityTypeMetadata } from "../api/useFetchPaginatedShiftBlocks";
import { SHIFT_BLOCK_ACTIONS } from "../utils";

interface ShiftBlockFacilityDetailsProps {
  facility: FacilityTypeMetadata["attributes"] & { id: string };
  isShiftBlockAssigned: boolean;
}

export function ShiftBlockFacilityDetails(props: ShiftBlockFacilityDetailsProps) {
  const { facility, isShiftBlockAssigned } = props;
  const { path: currentRouterUrl } = useRouteMatch();

  const { data: facilityRatings = RATING_AGGREGATE_EMPTY, isSuccess: isSuccessRatings } =
    useGetRatingAggregates(facility.id, {
      enabled: isDefined(facility.id),
    });

  const isExtraTimePayEnabled =
    isDefined(facility.extraTimePaySettings?.workdayOptions) ||
    isDefined(facility.extraTimePaySettings?.weeklyOption);
  return (
    <InternalLink
      to={`${RootPaths.HOME}/facility/${facility.id}?shiftBookingType=block-booking`}
      underline="hover"
      onClick={() => {
        logEvent(APP_V2_USER_EVENTS.SHIFT_BLOCKS, {
          action: SHIFT_BLOCK_ACTIONS.FACILITY_PROFILE_CARD_TAPPED,
          facilityId: facility.id,
          isShiftBlockAssigned,
          location: currentRouterUrl,
        });
      }}
    >
      <Card variant="outlined">
        <CardContent
          sx={{
            padding: 2,
            "&:last-child": {
              paddingBottom: 2,
            },
          }}
        >
          <Stack spacing={0.5}>
            <Text
              sx={{
                fontWeight: 600,
                paddingLeft: 0.25,
              }}
            >
              {facility.name}
            </Text>
            <Stack spacing={0.5}>
              {isSuccessRatings && (
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Rating readOnly name="read-only" value={facilityRatings.avgRating} />
                  <Text>({facilityRatings.numberOfRatings} reviews)</Text>
                </Stack>
              )}
              <Text
                variant="body2"
                sx={{
                  paddingLeft: 0.25,
                }}
                aria-label="Facility Distance"
              >
                {formatDistanceInMiles(facility.distance)}. from home
              </Text>
            </Stack>
            {isExtraTimePayEnabled && (
              <Stack
                direction="row"
                spacing={0.5}
                alignItems="center"
                sx={{ color: (theme) => theme.palette.primary.main }}
              >
                <TimerOutlinedIcon color="inherit" fontSize="small" />
                <Text color="inherit" variant="body2">
                  Extra Time Pay Facility
                </Text>
              </Stack>
            )}
          </Stack>
        </CardContent>
      </Card>
    </InternalLink>
  );
}
