import { formatShortDateWithWeekday, formatTime } from "@clipboard-health/date-time";
import { Text } from "@clipboard-health/ui-react";
import BoltIcon from "@mui/icons-material/Bolt";
import { Chip, Stack } from "@mui/material";
import { formatDollarsAsUsd } from "@src/appV2/lib/Money/utils/currency";
import pluralize from "pluralize";

import { type ShiftBlockWithMappedDetails } from "../api/useFetchPaginatedShiftBlocks";
import { getHumanReadableShiftDuration } from "../utils";
import { LunchBreakInformationIconAndDialog } from "./LunchBreakInformationIconAndDialog";

interface ShiftBlockHeaderProps {
  shiftBlockWithDetails: ShiftBlockWithMappedDetails;
  agentTimezone: string;
}

export function ShiftBlockHeader(props: ShiftBlockHeaderProps) {
  const { shiftBlockWithDetails, agentTimezone } = props;
  const { blockStartTime, blockEndTime, facility, shifts, totalPay, agentReq, totalTime } =
    shiftBlockWithDetails;
  const finalPay =
    shifts.reduce((sum, shift) => {
      const offerPay = shift.offer?.pay?.value ?? shift.pay;
      const shiftTime = shift.time;
      return sum + offerPay * shiftTime;
    }, 0) || totalPay;
  const firstShiftDetails = shifts[0];
  const shiftsLength = shifts.length;
  const lastShiftDetails = shifts[shiftsLength - 1];
  const hourlyPay = totalTime === 0 ? firstShiftDetails.pay : finalPay / totalTime;
  // If hourly pay is different for at least one shift, show the approximate pay symbol
  const showApproximatePaySymbol = shifts.some((shift) => {
    const shiftPay = shift.offer?.pay?.value ?? shift.pay;
    return shiftPay !== hourlyPay;
  });

  return (
    <Stack spacing={0.25}>
      <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
        <Text sx={{ color: (theme) => theme.palette.success.main, fontWeight: 600 }} variant="h3">
          {formatDollarsAsUsd(finalPay, { minimumFractionDigits: 2 })} total
        </Text>

        <Text
          variant="h3"
          sx={{
            opacity: 0.6,
          }}
        >
          {shifts.length} {pluralize("shift", shifts.length)}
        </Text>
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
        <Text>
          {formatTime(blockStartTime, { timeZone: agentTimezone })} -{" "}
          {formatTime(blockEndTime, { timeZone: agentTimezone })}
        </Text>

        <Stack direction="row" alignItems="center">
          <Text
            sx={{
              opacity: 0.6,
            }}
          >
            {getHumanReadableShiftDuration({ shiftDuration: firstShiftDetails.time })}
          </Text>
          <LunchBreakInformationIconAndDialog
            shiftDetails={{
              facilityName: facility.name,
              shiftTime: firstShiftDetails.time,
            }}
          />
        </Stack>
      </Stack>

      <Text>
        {formatShortDateWithWeekday(firstShiftDetails.start, { timeZone: agentTimezone })} -{" "}
        {formatShortDateWithWeekday(lastShiftDetails.start, {
          timeZone: agentTimezone,
        })}
      </Text>

      <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
        <Text
          sx={{
            color: (theme) => theme.palette.success.main,
            fontWeight: 600,
          }}
        >
          {showApproximatePaySymbol ? "~" : ""}
          {formatDollarsAsUsd(hourlyPay, { minimumFractionDigits: 2 })} / hr
        </Text>

        {firstShiftDetails.isInstantPay && (
          <Stack direction="row">
            <BoltIcon sx={{ color: (theme) => theme.palette.success.main }} />
            <Text bold sx={{ color: (theme) => theme.palette.success.main, fontWeight: 600 }}>
              InstantPay
            </Text>
          </Stack>
        )}
      </Stack>
      <Stack spacing={0.5} alignContent="start" direction="row">
        <Chip size="small" label={agentReq} />
        <Chip size="small" label="Block" />
      </Stack>
    </Stack>
  );
}
