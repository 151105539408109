import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { Card, CardContent, Skeleton, Stack } from "@mui/material";

export function ShiftBlockCardSkeleton() {
  return (
    <Card elevation={3}>
      <CardContent>
        <Stack spacing={1}>
          <Stack direction="row" spacing={2}>
            <Skeleton width={150} />
            <Skeleton width={50} />
          </Stack>
          <Stack direction="row" spacing={1}>
            <AccessTimeIcon color="disabled" />
            <Skeleton width={150} />
          </Stack>
          <Stack direction="row" spacing={1}>
            <DateRangeIcon color="disabled" />

            <Skeleton width={50} />
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <AttachMoneyOutlinedIcon color="disabled" />
            <Skeleton width={200} />
            <Skeleton width={50} />
          </Stack>
          <Card variant="outlined" aria-label="Facility details skeleton">
            <CardContent>
              <Skeleton width={150} />
              <Stack direction="row">
                <Skeleton width={50} />
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </CardContent>
    </Card>
  );
}
