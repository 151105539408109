import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Button } from "@mui/material";
import { useToast } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import {
  BookabilityDecision,
  type BookabilityStatusItem,
} from "@src/appV2/OpenShifts/ShiftAction/types";
import { useRouteMatch } from "react-router-dom";

import { type ShiftBlockWithMappedDetails } from "../api/useFetchPaginatedShiftBlocks";
import { BookShiftBlockErrors, SHIFT_BLOCK_ACTIONS } from "../utils";
import { RequestToBookShiftBlockDialog } from "./RequestToBookShiftBlockDialog";

interface RequestToBookShiftBlockButtonProps {
  disabled: boolean;
  facilityId: string;
  agentId: string;
  shiftBlockId: string;
  shifts: ShiftBlockWithMappedDetails["shifts"];
  shiftWithBlockedStatus?: BookabilityStatusItem;
}

export function RequestToBookShiftBlockButton(props: RequestToBookShiftBlockButtonProps) {
  const { disabled, facilityId, agentId, shiftBlockId, shifts, shiftWithBlockedStatus } = props;
  const requestToBookShiftBlockState = useModalState();
  const { showErrorToast } = useToast();
  const { path: currentRouterUrl } = useRouteMatch();
  return (
    <>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        size="medium"
        disabled={disabled}
        onClick={() => {
          if (
            isDefined(shiftWithBlockedStatus) &&
            shiftWithBlockedStatus.attributes.bookability.decision === BookabilityDecision.BLOCKED
          ) {
            logEvent(APP_V2_USER_EVENTS.SHIFT_BLOCKS, {
              action: SHIFT_BLOCK_ACTIONS.REQUEST_THIS_BLOCK_TAPPED,
              shiftBlockId,
              facilityId,
              unmetCriteria: shiftWithBlockedStatus.attributes.bookability.unmetCriteria,
              location: currentRouterUrl,
            });
            // There can be multiple reasons for the shift block booking being blocked, however for the purposes of showing the error message to the user, we are only showing the first unmet criteria.
            showErrorToast(
              BookShiftBlockErrors[
                shiftWithBlockedStatus.attributes.bookability.unmetCriteria[0]
              ] ?? "Error: You cannot request to book this block."
            );
          } else {
            logEvent(APP_V2_USER_EVENTS.SHIFT_BLOCKS, {
              action: SHIFT_BLOCK_ACTIONS.REQUEST_THIS_BLOCK_TAPPED,
              shiftBlockId,
              facilityId,
              location: currentRouterUrl,
            });
            requestToBookShiftBlockState.openModal();
          }
        }}
      >
        Request This Block
      </Button>

      <RequestToBookShiftBlockDialog
        modalState={requestToBookShiftBlockState}
        shifts={shifts}
        shiftBlockId={shiftBlockId}
        agentId={agentId}
        facilityId={facilityId}
      />
    </>
  );
}
