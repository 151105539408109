import { formatShortDateWithWeekday, formatTime } from "@clipboard-health/date-time";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import BoltIcon from "@mui/icons-material/Bolt";
import { Divider, Stack } from "@mui/material";
import { formatDollarsAsUsd } from "@src/appV2/lib/Money/utils/currency";
import { useStreaksEnabledGlobally } from "@src/appV2/Streaks";
import { StreakPayModifierBar } from "@src/appV2/Streaks/components/StreakPayModifierBar";
import { type Streak } from "@src/appV2/Streaks/types";
import { formatPayBoostPercentage, getShiftStreak } from "@src/appV2/Streaks/utils";
import { parseISO } from "date-fns";

import { type ShiftBlockWithMappedDetails } from "../api/useFetchPaginatedShiftBlocks";
import { getHumanReadableShiftDuration } from "../utils";
import { BookedShiftBlockActions } from "./BookedShiftBlockActions";
import { LunchBreakInformationIconAndDialog } from "./LunchBreakInformationIconAndDialog";

interface ShiftBlockShiftDetailsProps {
  shiftBlockWithDetails: ShiftBlockWithMappedDetails;
  agentTimezone: string;
  streaks: Streak[];
}
export function ShiftBlockShiftDetails(props: ShiftBlockShiftDetailsProps) {
  const { shiftBlockWithDetails, agentTimezone, streaks } = props;
  const {
    shifts,
    isShiftBlockAssigned,
    facility: { name: facilityName },
  } = shiftBlockWithDetails;

  const isStreaksEnabledGlobally = useStreaksEnabledGlobally();

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        <Text variant="h3">{isShiftBlockAssigned ? "Booked Block Shifts" : "Block Shifts"}</Text>
        <BookedShiftBlockActions shiftBlockWithDetails={shiftBlockWithDetails} />
      </Stack>

      {shifts.map((shift, index) => {
        const shiftActiveStreak = getShiftStreak({
          hcpStreaks: streaks,
          shiftStart: shift.start,
        });
        return (
          <Stack key={shift.id} spacing={1}>
            <Stack>
              <Text>
                {formatShortDateWithWeekday(parseISO(shift.start), { timeZone: agentTimezone })}
              </Text>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Text>
                  {formatTime(parseISO(shift.start), { timeZone: agentTimezone })} -{" "}
                  {formatTime(parseISO(shift.end), { timeZone: agentTimezone })}
                </Text>
                <Stack direction="row" alignItems="center">
                  <Text
                    sx={{
                      opacity: 0.6,
                    }}
                  >
                    {getHumanReadableShiftDuration({ shiftDuration: shift.time })}
                  </Text>
                  <LunchBreakInformationIconAndDialog
                    shiftDetails={{ facilityName, shiftTime: shift.time }}
                  />
                </Stack>
              </Stack>
              <Stack
                direction="row"
                spacing={0.5}
                alignItems="center"
                justifyContent="space-between"
              >
                <Text
                  sx={{
                    color: (theme) => theme.palette.success.main,
                    fontWeight: 600,
                  }}
                  aria-label="Shift Price"
                >
                  {formatDollarsAsUsd(shift.offer?.pay?.value ?? shift.pay, {
                    minimumFractionDigits: 2,
                  })}
                  /hr
                </Text>

                {shift.isInstantPay && (
                  <Stack direction="row" spacing={0.25}>
                    <BoltIcon sx={{ color: (theme) => theme.palette.success.main }} />
                    <Text sx={{ color: (theme) => theme.palette.success.main, fontWeight: 600 }}>
                      InstantPay
                    </Text>
                  </Stack>
                )}
              </Stack>
            </Stack>
            {isStreaksEnabledGlobally && isDefined(shiftActiveStreak) && (
              <StreakPayModifierBar
                streakPayDescription={`You're earning ${formatPayBoostPercentage(
                  shiftActiveStreak.attributes.payBoostPercentage
                )} more on this shift because you worked more than ${Math.min(
                  shiftActiveStreak.attributes.minHoursForActiveStreak
                )} hours last week at this facility.`}
              />
            )}
            {index !== shifts.length - 1 && <Divider />}
          </Stack>
        );
      })}
    </>
  );
}
