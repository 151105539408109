import { isDefined } from "@clipboard-health/util-ts";
import { Button, Card, CardContent, Stack } from "@mui/material";
import { RootPaths } from "@src/appV2/App/paths";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import {
  formatPayBoostPercentage,
  StreakPayModifierBar,
  useStreaksEnabledGlobally,
} from "@src/appV2/Streaks";
import { type Streak } from "@src/appV2/Streaks/types";
import { generatePath, useHistory, useRouteMatch } from "react-router-dom";

import { type ShiftBlockWithMappedDetails } from "../api/useFetchPaginatedShiftBlocks";
import { SHIFT_BLOCKS_PATH } from "../paths";
import { SHIFT_BLOCK_ACTIONS } from "../utils";
import { PriorityAccessAlert } from "./PriorityAccessAlert";
import { ShiftBlockFacilityDetails } from "./ShiftBlockFacilityDetails";
import { ShiftBlockHeader } from "./ShiftBlockHeader";

interface ShiftBlockItemProps {
  shiftBlock: ShiftBlockWithMappedDetails;
  agentTimezone: string;
  shouldShowFacilityDetails: boolean;
  activeStreaksWithPayBoost?: Streak[];
}

export function ShiftBlockItem(props: ShiftBlockItemProps) {
  const { shiftBlock, agentTimezone, shouldShowFacilityDetails, activeStreaksWithPayBoost } = props;

  const isStreaksEnabledGlobally = useStreaksEnabledGlobally();

  const history = useHistory();
  const { path: currentRouterUrl } = useRouteMatch();
  const { id: shiftBlockId, facility, isShiftBlockAssigned, priorityAccess } = shiftBlock;

  return (
    <Card variant="outlined">
      <CardContent>
        <Stack spacing={2}>
          <ShiftBlockHeader shiftBlockWithDetails={shiftBlock} agentTimezone={agentTimezone} />
          <Stack spacing={2}>
            {(isDefined(priorityAccess) && isDefined(priorityAccess.priorityTill)) ||
            (isDefined(activeStreaksWithPayBoost) && activeStreaksWithPayBoost?.length > 0) ? (
              <Stack spacing={1}>
                {isDefined(priorityAccess) && isDefined(priorityAccess.priorityTill) && (
                  <PriorityAccessAlert priorityAccessTill={priorityAccess.priorityTill} />
                )}

                {isStreaksEnabledGlobally && isDefined(activeStreaksWithPayBoost) && (
                  <StreakPayModifierBar
                    streakPayDescription={`You're earning ${formatPayBoostPercentage(
                      Math.max(
                        ...activeStreaksWithPayBoost.map(
                          (streak) => streak.attributes.payBoostPercentage
                        )
                      )
                    )} more on certain shifts because you worked more than ${Math.min(
                      ...activeStreaksWithPayBoost.map(
                        (streak) => streak.attributes.minHoursForActiveStreak
                      )
                    )} hours in the week prior to the shift.`}
                  />
                )}
              </Stack>
            ) : null}

            {shouldShowFacilityDetails && (
              <ShiftBlockFacilityDetails
                facility={facility}
                isShiftBlockAssigned={isShiftBlockAssigned}
              />
            )}

            <Button
              variant="contained"
              onClick={() => {
                logEvent(APP_V2_USER_EVENTS.SHIFT_BLOCKS, {
                  action: SHIFT_BLOCK_ACTIONS.VIEW_BLOCK_DETAILS_TAPPED,
                  shiftBlockId,
                  facilityId: facility.id,
                  location: currentRouterUrl,
                });
                history.push(
                  generatePath(`${RootPaths.APP_V2_HOME}/${SHIFT_BLOCKS_PATH}/:shiftBlockId`, {
                    shiftBlockId,
                  })
                );
              }}
            >
              View Block Details
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
